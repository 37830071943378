<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Информация о группе
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <div>
              {{groupInfo.groupName}}
            </div>
            <b-tabs pills card id="">
              <b-tab @click="reloadAbsenceLog">
                <template v-slot:title>
                  Журнал отсутсвующих
                </template>
                <PreschoolAbsenceLog :group-id="groupId+''" ref="preschoolAbsenceLog"></PreschoolAbsenceLog>
              </b-tab>
            </b-tabs>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import PreschoolAbsenceLog from "./partials/AbsenceLog.vue";


export default {
  name: "PreschoolGroupInfo",
  components: {
    KTPortlet,
    PreschoolAbsenceLog
  },
  data() {
    return {
      groupId:this.$route.params.id,
      groupInfo: {},
    };
  },
  mounted() {
    ApiService.querySecured("preschoolGroups/find", {
      params: {id: this.$route.params.id}
    }).then(({data}) => {
      this.groupInfo = data;
    }).catch(({response}) => {
      console.log(response);
    });
  },
  methods: {
    reloadAbsenceLog(){
      this.$refs['preschoolAbsenceLog'].reloadData();
    }
  }
};
</script>
